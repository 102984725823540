<template>
  <el-card>
    <div class="filter-wrap flex-between">
      <div class="flex-start search-filter-wrap">
        <el-input
          v-model="listQuery.searchStr"
          width="180"
          placeholder="请输入内容"
          class="mr10"
          clearable
          @clear="handleFilter"
        />
        <el-select
          v-model="listQuery.typeCode"
          clearable
          class="mr10"
          style="width: 320px"
          placeholder="请选择类型"
        >
          <el-option
            v-for="(item,index) in lawTypeOptions"
            :key="index"
            :label="item.typeName"
            :value="item.typeCode"
          />
        </el-select>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
        >
          搜索
        </el-button>
      </div>
      <div class="text-right">
        <el-button
          class="mr10"
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="openImportDialog"
        >
          导入
        </el-button>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="openDialog(1)"
        >
          新增
        </el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      fit
      highlight-current-row
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="编号"
        prop="code"
      />
      <el-table-column
        label="名称"
        prop="lawName"
      />
      <el-table-column
        label="类型"
        prop="typeName"
      />
      <el-table-column
        label="所属国家"
        prop="nation"
      />
      <el-table-column
        label="发布机构"
        prop="issueOrg"
      />
      <el-table-column
        label="颁布时间"
        prop="issueTime"
      >
        <template slot-scope="{row}">
          {{ row.issueTime ? formatDateStr(row.issueTime) : '' }}
        </template>
      </el-table-column>
      <el-table-column
        label="实施时间"
        prop="implementationTime"
      >
        <template slot-scope="{row}">
          {{ row.implementationTime ? formatDateStr(row.implementationTime) : '' }}
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        prop="status"
      >
        <template slot-scope="{row}">
          {{ statusObj[row.status] }}
        </template>
      </el-table-column>
      <el-table-column
        label="收录人"
        prop="createName"
      />
      <el-table-column
        label="收录时间"
        prop="createTime"
      >
        <template slot-scope="{row}">
          {{ row.createTime ? formatDateStr(row.createTime) : '' }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="120px"
      >
        <template slot-scope="{row}">
          <el-link
            class="mr10"
            type="primary"
            @click="openDialog(2,row)"
          >
            <el-tooltip
              content="编辑"
              placement="top"
              effect="light"
            >
              <i class="el-icon-edit" />
            </el-tooltip>
          </el-link>
          <el-link
            class="mr10"
            type="primary"
            @click="gotoLaw(row)"
          >
            <el-tooltip
              content="条文详情"
              placement="top"
              effect="light"
            >
              <i class="el-icon-document" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            type="primary"
            @click="delLaw(row.id)"
          >
            <el-tooltip
              content="删除"
              placement="top"
              effect="light"
            >
              <i class="el-icon-delete" />
            </el-tooltip>
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.pageSize"
      @pagination="getList"
    />
    <LawsSave
      ref="lawsSave"
      @handleFilter="handleFilter"
    />
    <ImportLaws
      ref="importLaws"
      @handleFilter="handleFilter"
    />
  </el-card>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import { error, formatDateStr, success } from '@core/utils/utils'
import { KnowledgeLawPage, DeleteLaw, GetLawTypeOption } from '@/api/knowledgeBase/laws.js'
import LawsSave from '@/views/knowledge/laws/LawsSave.vue'
import ImportLaws from '@/views/knowledge/laws/ImportLawsDetail.vue'

export default {
  components: {
    pagination,
    LawsSave,
    ImportLaws,
  },

  data() {
    return {
      lawTypeOptions: [],
      loading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        searchStr: '',
        typeCode: '',
      },
      tableData: [],
      //  1征集意见 2正式使用 停用
      statusObj: {
        1: '征集意见',
        2: '正式使用',
        3: '停用',
      },
    }
  },
  created() {
    this.getList()
    this.getLawTypeOption()
  },
  methods: {
    formatDateStr,
    getLawTypeOption() {
      GetLawTypeOption().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.lawTypeOptions = resData.data
        }
      })
    },
    gotoLaw(row) {
      this.$router.push({
        path: '/lawDetail',
        query: {
          c: row.code,
          id: row.id,
        },
      })
    },
    openImportDialog() {
      this.$refs.importLaws.dialogVisible = true
    },
    openDialog(saveType, row) {
      this.$refs.lawsSave.dialogVisible = true
      if (saveType === 2) {
        this.$refs.lawsSave.form.id = Number(row.id)
      }
    },
    delLaw(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        DeleteLaw({ ids: [id] }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    getList() {
      this.loading = true
      KnowledgeLawPage(this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>
