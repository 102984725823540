<template>
  <el-dialog
    :title="form.id ? '修改法律' : '新增法律'"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form"
      label-width="100px"
    >
      <el-form-item
        label="名称"
        prop="lawName"
        :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.lawName"
        />
      </el-form-item>
      <el-form-item
        label="编号"
        prop="code"
        :rules="[ { required: true, message: '编号不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.code"
        />
      </el-form-item>
      <el-form-item
        label="类型"
        prop="typeCode"
        :rules="[ { required: true, message: '类型不能为空', trigger: 'change' }]"
      >
        <el-select v-model="form.typeCode">
          <el-option
            v-for="(item,index) in lawTypeOptions"
            :key="index"
            :label="item.typeName"
            :value="item.typeCode"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="所属国家"
      >
        <el-input
          v-model="form.nation"
        />
      </el-form-item>
      <el-form-item
        label="发布机关"
      >
        <el-input
          v-model="form.issueOrg"
        />
      </el-form-item>
      <el-form-item label="颁布时间">
        <el-date-picker
          v-model="form.issueTime"
          placement="bottom-start"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
        />
      </el-form-item>
      <el-form-item label="实施时间">
        <el-date-picker
          v-model="form.implementationTime"
          placement="bottom-start"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
        />
      </el-form-item>
      <el-form-item
        label="状态"
        prop="status"
        :rules="[ { required: true, message: '状态不能为空', trigger: 'change' }]"
      >
        <el-select v-model="form.status">
          <el-option
            v-for="(item,index) in statusOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import {
  GetDetailsOptions, GetLawById, GetLawTypeOption, GetLawTypeOptionTree, saveOrUpdateLaw,
} from '@/api/knowledgeBase/laws'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      typeCodeOptions: [],
      statusOptions: [
        { label: '征集意见', value: 1 },
        { label: '正式生效', value: 2 },
        { label: '停用', value: 3 },
      ],
      title: '',
      form: {
        id: 0,
        code: '',
        implementationTime: '',
        issueTime: '',
        issueOrg: '',
        labelName: '',
        nation: '',
        status: '',
        typeCode: '',
      },
      lawTypeOptions: [],
    }
  },
  methods: {
    getLawTypeOption() {
      GetLawTypeOption().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.lawTypeOptions = resData.data
        }
      })
    },
    getLawById() {
      GetLawById({ id: this.form.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = { ...resData.data }
        }
      })
    },
    dialogOpen() {
      this.getLawTypeOption()
      if (this.form.id) {
        this.getLawById()
      }
    },
    dialogClose() {
      this.form = {
        id: 0,
        code: '',
        implementationTime: '',
        issueTime: '',
        issueOrg: '',
        labelName: '',
        nation: '',
        status: '',
        typeCode: '',
      }
      this.$refs.form.resetFields()
    },
    // eslint-disable-next-line vue/no-dupe-keys
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          if (!this.form.id) {
            delete this.form.id
          }
          saveOrUpdateLaw(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          }).catch(() => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}
.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
</style>
