import { get, post, downloadByGet } from '@/libs/axios'
export const KnowledgeLawPage = params => post('/knowledge/law/Page', params)
export const LawsCasePage = params => post('/knowledge/lawCase/Page', params)
export const LawUnscramblePage = params => post('/knowledge/lawUnscramble/Page', params)
export const DeleteLaw = params => post('/knowledge/law/DeleteLawByIds', params)
export const LawDetailsPage = params => post('/knowledge/lawDetails/Page', params)
export const BestPracticePage = params => post('/knowledge/bestPractice/Page', params)
export const DeleteLawDetailsByIds = params => post('/knowledge/lawDetails/DeleteLawDetailsByIds', params)
export const DeleteLawTypeByIds = params => post('/knowledge/lawType/DeleteLawTypeByIds', params)
export const DeleteUnscrambleBasePractice = params => post('/knowledge/lawUnscramble/DeleteUnscrambleBasePractice', params)
export const DeleteLawCaseByIds = params => post('/knowledge/lawCase/DeleteLawCaseByIds', params)
export const DeleteLawUnscrambleByIds = params => post('/knowledge/lawUnscramble/DeleteLawUnscrambleByIds', params)
export const DeleteLawBestPracticeByCodes = params => post('/knowledge/bestPractice/Delete', params)
export const GetLawById = id => post('/knowledge/law/GetLawById', id)
export const GetLawDetailsById = id => post('/knowledge/lawDetails/GetLawDetailsById', id)
export const GetLawTypeById = id => post('/knowledge/lawType/GetLawTypeById', id)
export const GetLawCaseById = id => post('/knowledge/lawCase/GetLawCaseById', id)
export const GetLawUnscrambleById = id => post('/knowledge/lawUnscramble/GetLawUnscrambleById', id)
export const GetLawUnscrambleOptions = lawCode => post('/knowledge/lawUnscramble/GetLawUnscrambleOptions', lawCode)
export const GetLawBestPracticeByCode = params => post('/knowledge/bestPractice/Info', params)
export const knowledgeLawTypePage = params => post('/knowledge/lawType/Page', params)
export const GetLawTypeOption = () => get('/knowledge/lawType/GetOption')
export const GetLawOptions = () => get('/knowledge/law/GetLawOptions')
export const GetBestPracticeOptions = () => get('/knowledge/bestPractice/GetOptionList')
export const GetDetailsOptions = params => post('/knowledge/lawDetails/GetDetailsOptions', params)
export const ImportLawDetail = params => post('/knowledge/lawDetails/Import', params)
export const DownloadImportTemplate = () => downloadByGet(`/knowledge/lawDetails/DownloadImportTemplate?_t=${Date.parse(new Date())}`)
export const saveOrUpdateLaw = params => {
  const submitUrl = params.id ? '/knowledge/law/UpdateLaw' : '/knowledge/law/CreateLaw'
  return post(submitUrl, params)
}
export const saveOrUpdateLawDetail = params => {
  const submitUrl = params.id ? '/knowledge/lawDetails/UpdateLawDetails' : '/knowledge/lawDetails/CreateLawDetails'
  return post(submitUrl, params)
}
export const saveOrUpdateLawType = params => {
  const submitUrl = params.id ? '/knowledge/lawType/UpdateLawType' : '/knowledge/lawType/CreateLawType'
  return post(submitUrl, params)
}
//
export const saveOrUpdateLawCase = params => {
  const submitUrl = params.id ? '/knowledge/lawCase/UpdateLawCase' : '/knowledge/lawCase/CreateLawCase'
  return post(submitUrl, params)
}
export const saveOrUpdateLawUnscramble = params => {
  const submitUrl = params.id ? '/knowledge/lawUnscramble/UpdateLawUnscramble' : '/knowledge/lawUnscramble/CreateLawUnscramble'
  return post(submitUrl, params)
}
//
export const saveOrUpdateLawBestPractice = params => {
  const submitUrl = params.id ? '/knowledge/bestPractice/Update' : '/knowledge/bestPractice/Create'
  return post(submitUrl, params)
}
